import React from 'react';

import CityLeft from '../../illustrations/CityLeft';
import CityRight from '../../illustrations/CityRight';

import s from './cityIllustrations.module.scss';

const CityIllustrations = () => (
  <div className={s.cityIllustrationWrapper}>
    <div className={s.cityLeft}>
      <CityLeft />
    </div>
    <div className={s.cityRight}>
      <CityRight />
    </div>
  </div>
);

export default CityIllustrations;

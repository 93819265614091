import React from 'react';

import DesktopClouds from '../../images/clouds_desktop.svg';
import MobileClouds from '../../images/clouds_mobile.svg';

import s from './clouds.module.scss';

const Clouds = () => (
  <>
    <img className={s.cloudsDesktop} src={DesktopClouds} aria-hidden="true" alt="Clouds" />
    <img className={s.cloudsMobile} src={MobileClouds} aria-hidden="true" alt="Clouds" />
  </>
);

export default Clouds;

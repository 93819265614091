import React from 'react';

import Balloon from '../../illustrations/Balloon';

import s from './balloonIllustration.module.scss';

const BallonIllustration = () => (
  <div className={s.balloonIllustrationWrapper}>
    <Balloon />
  </div>
);

export default BallonIllustration;

import React from 'react';

import s from './logo.module.scss';

const Logo = () => (
  <div className={s.logoWrapper}>
    <svg focusable="false" width="71" height="48" viewBox="0 0 71 48" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor">
        <path d="M14.162 23.683V11.965h2.282V21.5h5.225v2.183m2.47-12.168c.74 0 1.35.604 1.35 1.35 0 .748-.6 1.356-1.35 1.362-.748.005-1.358-.594-1.366-1.34-.004-.363.137-.712.39-.97.256-.257.603-.402.966-.402zm-1.09 12.163v-8.125h2.2v8.13h-2.2v-.005zm8.64.005H29.5l-3.32-8.13h2.414l2.02 5.443 1.934-5.443h2.318m5.922 8.13H38.59l-3.32-8.13h2.396l2.02 5.443 1.933-5.443h2.32m2.24-4.038c.74 0 1.35.604 1.35 1.35 0 .748-.6 1.356-1.35 1.362-.75.005-1.36-.594-1.37-1.34-.003-.363.14-.712.393-.97.255-.257.602-.402.964-.402zm-1.09 12.168v-8.13h2.19v8.13h-2.19zm6.22 0h-2.2v-8.13h2.13v.99c.512-.79 1.4-1.252 2.342-1.223 2.014 0 2.944 1.44 2.944 3.222v5.122h-2.19v-4.746c0-.91-.447-1.618-1.487-1.618-.43 0-.845.184-1.134.506-.29.32-.43.75-.386 1.18v4.678l-.022.02zM24.8 37.39c-.07.484-.166.963-.287 1.436l-.703-.18c.115-.445.206-.898.272-1.354l.718.1zm-.743 2.826c-.18.456-.388.9-.62 1.33l-.616-.335c.22-.4.42-.82.59-1.24l.65.25zm-1.41 2.568c-.29.395-.61.77-.95 1.124l-.494-.495c.317-.332.613-.684.885-1.054l.555.42zm-2.03 2.113c-.38.307-.78.59-1.2.847l-.367-.6c.393-.24.77-.504 1.13-.79l.434.543zm-2.477 1.54c-.442.203-.895.384-1.356.54l-.224-.664c.438-.142.867-.307 1.287-.495l.294.618zm-2.76.935c-.466.108-.95.193-1.436.257l-.09-.7c.465-.06.925-.15 1.37-.24l.154.68z" />
        <path d="M.06 0v36.62H0h11.44V48h.99V34.144H2.534V2.474H68.35V0" />
        <path d="M24.307 34.144h44.04v2.474h-44.04zm46.515-31.67h-2.514.04v31.67h2.474" />
        <path d="M68.348 34.144h2.474v2.474h-2.474zm2.474-31.67V0h-2.474v2.474h-.04" />
      </g>
    </svg>
  </div>
);

export default Logo;

import React from 'react';

import Container from '../Container';

import s from './eolMessage.module.scss';

const EolMessage = () => (
  <Container>
    <div className={s.eolMessage}>
      <h1 className={s.eolTitle}>Kjære Livvin-venner</h1>
      <div className={s.eolText}>
        <p>Takk for reisen. Vi har nå stengt.</p>
        <p>Boligmarkedet blir ikke det samme uten.</p>
      </div>
    </div>
  </Container>
);

export default EolMessage;

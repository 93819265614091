import React from 'react';

const CityLeft = () => (
  <svg width="457" height="167" version="1.1" viewBox="0 0 456 167" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-12 -432)">
        <g transform="translate(12 432)">
          <path d="m370.87 72.704h11.828m-63.081 0h12.724m-22.222 0h4.1217m72.937 0h4.1217m4.4802 0h0.89603m-91.574 0h0.89603m142.47-9.3398h-5.9138m12.724 0h0.71682m-85.302 9.3398c0-5.9272-4.8386-10.777-10.752-10.777m-16.845 0c-5.9138 0-10.752 4.8495-10.752 10.777m27.777-10.777c0-4.6699-3.7633-8.4417-8.4227-8.4417s-8.4227 3.7718-8.4227 8.4417m63.081 1.4369h27.777m-27.956 0c0-5.9272-4.8386-10.777-10.752-10.777m-16.845 0.17961c-5.9138 0-10.752 4.8495-10.752 10.777m27.777-10.777c0-4.6699-3.7633-8.4417-8.4227-8.4417s-8.4227 3.7718-8.4227 8.4417" opacity=".3" stroke="currentColor" />
          <path d="m57.077 26.364h10.573m7.3475 0h1.6129m39.425-8.2621h-5.3762m11.469 0h1.0752m-66.306 8.2621c0-5.3883-4.301-9.699-9.6771-9.699m0.17921 0.17961c0-4.3107-3.4049-7.7233-7.7059-7.7233m49.103 8.9806h14.337m-14.337 0c0-5.3883-4.301-9.699-9.6771-9.699m-15.233 0.17961c-5.3762 0-9.6771 4.3107-9.6771 9.699m24.91-9.699c0-4.3107-3.4049-7.7233-7.7059-7.7233s-7.7059 3.4126-7.7059 7.7233" opacity=".3" stroke="currentColor" />
          <path d="m203.13 152.27v14.728" stroke="currentColor" />
          <polyline points="152.59 167 152.59 141.14 196.68 141.14" stroke="currentColor" />
          <polyline points="290.94 167 290.94 126.41 316.03 126.41" stroke="currentColor" />
          <polyline points="354.56 126.41 360.83 126.41 360.83 149.76" stroke="currentColor" />
          <polyline points="196.68 70.369 202.95 70.369 202.95 130" stroke="currentColor" />
          <polyline points="128.76 102.16 134.31 102.16 134.31 117.43" stroke="currentColor" />
          <polyline points="102.42 102.16 57.435 102.16 57.435 128.38" stroke="currentColor" />
          <polyline points="57.435 134.67 50.983 134.67 50.983 167" stroke="currentColor" />
          <polyline points="158.87 70.369 152.59 70.369 152.59 141.14" stroke="currentColor" />
          <polyline points="190.94 25.825 196.68 25.825 196.68 70.369" stroke="currentColor" />
          <polyline points="202.95 77.553 228.58 77.553 228.58 83.84 240.94 83.84" stroke="currentColor" />
          <polyline points="171.95 25.825 158.87 25.825 158.87 70.369" stroke="currentColor" />
          <polyline points="168.01 15.587 190.94 15.587 190.94 25.825" stroke="currentColor" />
          <polyline points="168.01 15.587 164.6 15.587 164.6 25.825" stroke="currentColor" />
          <path d="m160.66 25.825h2.1505c1.0752 0 1.7921 0.71845 1.7921 1.7961v41.131c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.1505c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-41.131c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m166.93 15.587h2.1505c1.0752 0 1.7921 0.71845 1.7921 1.7961v6.6456c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.1505c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-6.6456c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m154.74 70.369h2.1505c1.0752 0 1.7921 0.71845 1.7921 1.7961v93.039c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.1505c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-93.039c0-0.89806 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m91.484 128.38h21.505c1.0752 0 1.7921 0.71845 1.7921 1.7961v35.024c0 1.0777-0.71682 1.7961-1.7921 1.7961h-21.505c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-35.024c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m57.435 103.06v30.714c0 0.53884-0.35841 0.89806-0.89603 0.89806h-4.4802c-0.53762 0-0.89603 0.35922-0.89603 0.89806v30.534c0 0.53884 0.35841 0.89806 0.89603 0.89806h6.8098s0.53762-2.8738 1.9713-3.7718c1.4337-0.89806 3.0465-1.6165 3.0465-1.6165v-26.044c0-0.53884 0.35841-0.89806 0.89603-0.89806h4.4802c0.53762 0 0.89603-0.35922 0.89603-0.89806v-30.714c0-0.53884-0.35841-0.89806-0.89603-0.89806h-10.932c-0.53762 0-0.89603 0.35922-0.89603 0.89806z" fill="currentColor" opacity=".1" />
          <path d="m2.5978 44.325h9.6771c1.0752 0 1.7921 0.71845 1.7921 1.7961v119.08c0 1.0777-0.71683 1.7961-1.7921 1.7961h-9.6771c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-119.08c0-0.89806 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m65.499 65.16h9.1395c1.0752 0 1.7921 0.71845 1.7921 1.7961v33.228c0 1.0777-0.71683 1.7961-1.7921 1.7961h-9.1395c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-33.228c0-0.89806 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m258.86 58.335h-17.025c-0.53762 0-0.89603 0.35922-0.89603 0.89806v81.005c0 0.53884 0.35841 0.89806 0.89603 0.89806h-6.4514c-0.53762 0-0.89603 0.35922-0.89603 0.89806v24.068c0 0.53884 0.35841 0.89806 0.89603 0.89806h17.204c0.53762 0 0.89603-0.35922 0.89603-0.89806v-24.068c0-0.53884-0.35841-0.89806-0.89603-0.89806h6.2722c0.53762 0 0.89603-0.35922 0.89603-0.89806v-81.005c0-0.53883-0.35841-0.89806-0.89603-0.89806z" fill="currentColor" opacity=".1" />
          <path d="m292.73 126.41h28.315c1.0752 0 1.7921 0.71845 1.7921 1.7961v37c0 1.0777-0.71683 1.7961-1.7921 1.7961h-28.315c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-37c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m299.72 117.07h27.777c1.0752 0 1.7921 0.71845 1.7921 1.7961v5.9272c0 1.0777-0.71682 1.7961-1.7921 1.7961h-27.777c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-5.9272c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m337.36 131.62h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m337.36 140.96h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m337.36 150.3h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m269.26 71.267h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m269.26 80.786h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m269.26 90.126h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m269.26 100.18h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m269.26 109.52h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m347.75 131.62h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m347.75 140.96h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m219.8 90.845h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m219.8 100.18h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m219.8 109.52h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m230.19 90.845h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m230.19 100.18h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m108.33 68.034h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m108.33 77.553h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m118.54 68.034h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m118.54 77.553h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m108.33 86.534h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m108.33 95.874h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m118.54 86.534h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m118.54 95.874h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m230.19 109.52h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m347.75 150.3h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m129.12 135.03h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m142.2 135.03h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m129.12 144.55h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m142.2 144.55h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m129.12 154.07h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m142.2 154.07h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m22.31 48.995h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m22.31 59.053h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m22.31 69.112h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m22.31 79.17h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m30.912 48.995h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m30.912 59.053h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m30.912 69.112h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m30.912 79.17h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m186.64 32.83h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m186.64 45.044h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m186.64 57.257h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m175.17 32.83h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m175.17 45.044h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m175.17 57.257h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m204.74 77.733h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v47.597c0 1.0777-0.71682 1.7961-1.7921 1.7961l-2.5089 1.0777c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-48.675c0-0.89806 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m89.692 155.68v-27.301" stroke="currentColor" />
          <path d="m114.78 153.53v13.471" stroke="currentColor" />
          <polyline points="84.495 128.38 102.42 117.43 134.49 117.43 152.24 128.38" stroke="currentColor" />
          <polyline points="63.707 65.16 76.431 51.689 116.04 51.689 128.76 65.16" stroke="currentColor" />
          <path d="m39.156 44.325v78.85" stroke="currentColor" />
          <path d="m39.156 145.63v21.374" stroke="currentColor" />
          <polyline points="17.293 44.325 0.98492 44.325 0.98492 167" stroke="currentColor" />
          <polyline points="0.98492 44.325 7.6156 37.141 32.525 37.141 39.156 44.325" stroke="currentColor" />
          <path d="m385.92 134.49c0-7.0049 5.5554-12.573 12.544-12.573 6.989 0 12.544 5.568 12.544 12.573" stroke="currentColor" />
          <path d="m411.19 141.67c0 7.0049-5.5554 12.573-12.544 12.573-6.989 0-12.544-5.568-12.544-12.573" stroke="currentColor" />
          <path d="m411.19 141.67v-7.1845" stroke="currentColor" />
          <path d="m398.46 167v-24.966" stroke="currentColor" />
          <path d="m253.49 155.33v-14.189" stroke="currentColor" />
          <path d="m240.94 134.67v-6.2864" stroke="currentColor" />
          <path d="m385.92 134.49v7.1845" stroke="currentColor" />
          <path d="m354.56 117.07v9.3398" stroke="currentColor" />
          <path d="m297.93 126.41v-9.3398" stroke="currentColor" />
          <polyline points="240.94 121.92 240.94 58.335 278.58 58.335 278.58 141.14" stroke="currentColor" />
          <path d="m128.76 65.16v37" stroke="currentColor" />
          <path d="m63.707 102.16v-37" stroke="currentColor" />
          <polyline points="234.49 167 234.49 141.14 240.94 141.14" stroke="currentColor" />
          <polyline points="285.03 167 285.03 141.14 278.58 141.14" stroke="currentColor" />
          <polyline points="329.29 117.07 295.6 117.07 309.4 103.6 343.09 103.6 356.89 117.07 349 117.07" stroke="currentColor" />
          <path d="m32.525 167v-25.864" stroke="currentColor" />
          <ellipse cx="32.525" cy="134.31" rx="12.903" ry="12.932" stroke="currentColor" />
          <path d="m343.09 103.6l-13.799 13.471" stroke="currentColor" />
          <path d="m134.31 117.43l-17.741 10.956" stroke="currentColor" />
          <path d="m84.495 128.38h5.197" stroke="currentColor" />
          <ellipse cx="209.58" cy="141.14" rx="12.903" ry="12.932" stroke="currentColor" />
          <path d="m209.58 167v-19.039" stroke="currentColor" />
          <rect x="243.27" y="155.33" width="20.25" height="4.8495" stroke="currentColor" />
          <rect x="241.84" y="160" width="23.476" height="3.0534" stroke="currentColor" />
          <path d="m274.14 163.05v3.5922" stroke="currentColor" />
          <path d="m288.83 163.05v3.5922" stroke="currentColor" />
          <path d="m371.76 162.51c-2.5089 0-4.4802 1.9757-4.4802 4.4903" stroke="currentColor" />
          <path d="m383.41 167c0-2.5146-1.9713-4.4903-4.4802-4.4903" stroke="currentColor" />
          <path d="m378.93 162.51c0-1.9757-1.6129-3.5922-3.5841-3.5922s-3.5841 1.6165-3.5841 3.5922" stroke="currentColor" />
          <path d="m349.36 161.61c-2.6881 0-5.0178 2.335-5.0178 5.0291" stroke="currentColor" />
          <path d="m355.63 154.79c-3.4049 0-6.2722 2.8738-6.2722 6.2864" stroke="currentColor" />
          <path d="m371.94 161.07c0-3.4126-2.8673-6.2864-6.2722-6.2864" stroke="currentColor" />
          <path d="m365.67 154.79c0-2.6942-2.3297-5.0291-5.0178-5.0291-2.6881 0-5.0178 2.335-5.0178 5.0291" stroke="currentColor" />
          <path d="m70.338 162.51c2.5089 0 4.4802 1.9757 4.4802 4.4903" stroke="currentColor" />
          <path d="m58.689 167c0-2.5146 1.9713-4.4903 4.4802-4.4903" stroke="currentColor" />
          <path d="m63.17 162.51c0-1.9757 1.6129-3.5922 3.5841-3.5922s3.5841 1.6165 3.5841 3.5922" stroke="currentColor" />
          <path d="m92.739 161.61c2.6881 0 5.0178 2.335 5.0178 5.0291" stroke="currentColor" />
          <path d="m86.466 154.79c3.4049 0 6.2722 2.8738 6.2722 6.2864" stroke="currentColor" />
          <path d="m70.159 161.07c0-3.4126 2.8673-6.2864 6.2722-6.2864" stroke="currentColor" />
          <path d="m76.431 154.79c0-2.6942 2.3297-5.0291 5.0178-5.0291 2.6881 0 5.0178 2.335 5.0178 5.0291" stroke="currentColor" />
        </g>
      </g>
    </g>

  </svg>
);

export default CityLeft;

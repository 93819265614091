import React from 'react';

const CityRight = () => (
  <svg width="500" height="167" version="1.1" viewBox="0 0 500 167" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-928 -432)">
        <g transform="translate(928 433)">
          <path d="m189.6 35.602h10.573m-19.533 0h1.6129m-35.841-8.2621h-5.3762m-7.1683 0h1.0752m74.908-1.4369c-5.3762 0-9.6771 4.3107-9.6771 9.699m17.204-17.243c-4.301 0-7.7059 3.4126-7.7059 7.7233m-55.554 1.2573h14.337m9.4979-9.699c-5.3762 0-9.6771 4.3107-9.6771 9.699m34.587 0c0-5.3883-4.301-9.699-9.6771-9.699 0-4.3107-3.4049-7.7233-7.7059-7.7233-4.301 0-7.7059 3.4126-7.7059 7.7233" opacity=".3" stroke="currentColor" />
          <path d="m73.471 77.092h11.828m38.529 0h12.724m5.3762 0h4.1217m-81.001 0h4.1217m-9.4979 0h0.89603m89.782 0h0.89603m-137.63-9.1602h-5.9138m-7.3475 0h0.71683m94.979-1.6165c-5.9138 0-10.752 4.8495-10.752 10.777m38.529 0c0-5.9272-4.8386-10.777-10.752-10.777 0-4.6699-3.7633-8.4417-8.4227-8.4417-4.6594 0-8.4227 3.7718-8.4227 8.4417m-74.191 1.6165h27.777m10.752-10.777c-5.9138 0-10.752 4.8495-10.752 10.777m38.529 0c0-5.9272-4.8386-10.777-10.752-10.777 0-4.6699-3.7633-8.4417-8.4227-8.4417-4.6594 0-8.4227 3.7718-8.4227 8.4417" opacity=".3" stroke="currentColor" />
          <path d="m316.47 85.534v-3.7718" stroke="currentColor" />
          <path d="m417.19 64.699h-13.082" stroke="currentColor" />
          <polyline points="164.69 166 164.69 141.03 101.07 141.03 101.07 162.05" stroke="currentColor" />
          <polyline points="240.13 166 240.13 146.96 164.69 146.96 164.69 166" stroke="currentColor" />
          <path d="m365.22 102.96h-22.401c-0.53762 0-0.89603 0.35922-0.89603 0.89806v61.068c0 0.53884 0.35841 0.89806 0.89603 0.89806h5.197c0-4.8495 4.4802-5.2087 4.4802-5.2087 0-3.233 3.5841-6.8252 6.4514-6.8252 0 0 0.89603-3.5922 3.0465-4.4903 2.3297-0.89806 4.1217-0.35922 4.1217-0.35922v-44.903c0-0.71845-0.35841-1.0777-0.89603-1.0777z" fill="currentColor" opacity=".1" />
          <path d="m237.98 115.71h-14.516c-1.0752 0-1.7921 0.71845-1.7921 1.7961v27.84c0 1.0777 0.71682 1.7961 1.7921 1.7961h14.516c1.0752 0 1.7921-0.71845 1.7921-1.7961v-27.84c0-0.89806-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m162.9 141.03h-32.436c-1.0752 0-1.7921 0.71845-1.7921 1.7961v21.374c0 1.0777 0.71682 1.7961 1.7921 1.7961h32.436c1.0752 0 1.7921-0.71845 1.7921-1.7961v-21.374c0-1.0777-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m257.34 39.913h-1.7921c-1.0752 0-1.7921 0.71845-1.7921 1.7961v122.5c0 1.0777 0.71683 1.7961 1.7921 1.7961h1.7921c1.0752 0 1.7921-0.71845 1.7921-1.7961v-122.5c0-0.89806-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m443.71 128.46h-19.713c-1.0752 0-1.7921 0.71845-1.7921 1.7961v33.947c0 1.0777 0.71682 1.7961 1.7921 1.7961h19.713c1.0752 0 1.7921-0.71845 1.7921-1.7961v-33.947c0-1.0777-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m301.78 6.1456h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v4.3107c0 1.0777 0.71683 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-4.3107c0-0.89806-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m232.43 52.306h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v9.5194c0 1.0777 0.71683 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-9.3398c0-1.0777-0.89603-1.9757-1.7921-1.9757z" fill="currentColor" opacity=".3" />
          <path d="m307.87 114.99h-8.7811c-1.0752 0-1.7921 0.71845-1.7921 1.7961v9.3398c0 1.0777 0.71683 1.7961 1.7921 1.7961h8.7811c1.0752 0 1.7921-0.71845 1.7921-1.7961v-9.3398c0-1.0777-0.71682-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m144.8 124.51v2.5146c0 1.0777 0.71682 1.7961 1.7921 1.7961h2.5089c1.0752 0 1.7921-0.71845 1.7921-1.7961v-2.5146c0-1.0777-0.71682-1.7961-1.7921-1.7961h-2.5089c-1.0752 0-1.7921 0.71845-1.7921 1.7961z" fill="currentColor" opacity=".3" />
          <path d="m153.93 124.51v2.5146c0 1.0777 0.71682 1.7961 1.7921 1.7961h2.5089c1.0752 0 1.7921-0.71845 1.7921-1.7961v-2.5146c0-1.0777-0.71683-1.7961-1.7921-1.7961h-2.5089c-1.0752 0-1.7921 0.71845-1.7921 1.7961z" fill="currentColor" opacity=".3" />
          <path d="m403.57 9.5583h-4.1217c-0.53762 0-0.89603 0.35922-0.89603 0.89806v109.74c0 0.71845 0.89603 1.2573 1.6129 0.71845l4.1217-3.7718c0.17921-0.17961 0.35841-0.35922 0.35841-0.71845v-105.97c-0.17921-0.53883-0.71682-0.89806-1.0752-0.89806z" fill="currentColor" opacity=".1" />
          <path d="m320.78 114.99h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v9.3398c0 1.0777 0.71683 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-9.3398c0-1.0777-0.71683-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m332.78 114.99h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v9.3398c0 1.0777 0.71682 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-9.3398c0-1.0777-0.71682-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m307.87 130.98h-8.7811c-1.0752 0-1.7921 0.71845-1.7921 1.7961v9.3398c0 1.0777 0.71683 1.7961 1.7921 1.7961h8.7811c1.0752 0 1.7921-0.71845 1.7921-1.7961v-9.3398c0-0.89806-0.71682-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m320.78 130.98h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v9.3398c0 1.0777 0.71683 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-9.3398c0-0.89806-0.71683-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m332.78 130.98h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v9.3398c0 1.0777 0.71682 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-9.3398c0-0.89806-0.71682-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m244.25 52.306h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v9.5194c0 1.0777 0.71683 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-9.3398c0-1.0777-0.71682-1.9757-1.7921-1.9757z" fill="currentColor" opacity=".3" />
          <path d="m232.43 70.087h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v9.3398c0 1.0777 0.71683 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-9.3398c0-1.0777-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m244.25 70.087h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v9.3398c0 1.0777 0.71683 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-9.3398c0-1.0777-0.71682-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m232.43 89.126h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v9.3398c0 1.0777 0.71683 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-9.3398c0-0.89806-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m244.25 89.126h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v9.3398c0 1.0777 0.71683 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-9.3398c0-0.89806-0.71682-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m263.61 20.874h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v15.626c0 1.0777 0.71682 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-15.626c0-1.0777-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m275.44 20.874h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v15.626c0 1.0777 0.71682 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-15.626c0-1.0777-0.71682-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m263.61 45.481h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v15.626c0 1.0777 0.71682 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-15.626c0-1.0777-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m275.44 45.481h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v15.626c0 1.0777 0.71682 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-15.626c0-1.0777-0.71682-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m263.61 69.908h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v15.626c0 1.0777 0.71682 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-15.626c0-0.89806-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m275.44 69.908h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v15.626c0 1.0777 0.71682 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-15.626c0-0.89806-0.71682-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m290.49 20.874h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v15.626c0 1.0777 0.71683 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-15.626c0-1.0777-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m290.49 45.481h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v15.626c0 1.0777 0.71683 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-15.626c0-1.0777-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m304.11 14.947v5.0291c0 0.53883-0.35841 0.89806-0.89603 0.89806h-4.301c-0.53762 0-0.89603 0.35922-0.89603 0.89806v17.422c0 0.53883 0.35841 0.89806 0.89603 0.89806h4.4802c0.53762 0 0.89603 0.35922 0.89603 0.89806v54.243c0 0.71845 0.71682 1.0777 1.2544 0.89806l10.573-4.8495c0.35841-0.17961 0.53762-0.53884 0.53762-0.89806v-75.437c0-0.53883-0.35841-0.89806-0.89603-0.89806h-10.573c-0.53762 0-1.0752 0.35922-1.0752 0.89806z" fill="currentColor" opacity=".1" />
          <path d="m295.51 0.21845h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v2.335c0 1.0777 0.71682 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-2.335c0-0.89806-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m396.76 1.1165h-2.6881c-1.0752 0-1.7921 0.71845-1.7921 1.7961v4.8495c0 1.0777 0.71683 1.7961 1.7921 1.7961h2.6881c1.0752 0 1.7921-0.71845 1.7921-1.7961v-4.8495c0-1.0777-0.89603-1.7961-1.7921-1.7961z" fill="currentColor" opacity=".1" />
          <path d="m455.18 107.45v-9.699c0-0.53883-0.35841-0.89806-0.89603-0.89806h-5.197c-0.53762 0-0.89603-0.35922-0.89603-0.89806v-54.961c0-0.53884-0.35841-0.89806-0.89603-0.89806h-22.222c-0.53762 0-0.89603 0.35922-0.89603 0.89806v54.961c0 0.53884 0.35841 0.89806 0.89603 0.89806h5.197c0.53762 0 0.89603 0.35922 0.89603 0.89806v9.699c0 0.53884 0.35841 0.89806 0.89603 0.89806h15.949c0.53762 0 0.89603 0.35922 0.89603 0.89806v55.68c0 0.53884 0.35841 0.89806 0.89603 0.89806h10.752c0.53762 0 0.89603-0.35922 0.89603-0.89806v-55.68c0-0.53884-0.35841-0.89806-0.89603-0.89806h-4.4802c-0.35841 0-0.89603-0.35922-0.89603-0.89806z" fill="currentColor" opacity=".1" />
          <path d="m366.12 149.12v-46.16" stroke="currentColor" />
          <path d="m290.67 151.45v14.549" stroke="currentColor" />
          <polyline points="316.47 102.96 290.67 102.96 290.67 122.71" stroke="currentColor" />
          <polyline points="164.69 146.96 240.13 146.96 240.13 115.89 139.06 115.89 139.06 128.82" stroke="currentColor" />
          <polyline points="227.77 39.913 221.67 39.913 221.67 102.06" stroke="currentColor" />
          <polyline points="259.13 165.82 259.13 39.913 246.05 39.913" stroke="currentColor" />
          <polyline points="253.04 39.913 253.04 32.908 227.77 32.908 227.77 39.913" stroke="currentColor" />
          <polyline points="359.13 9.5583 353.39 9.5583 353.39 96.85" stroke="currentColor" />
          <polyline points="404.11 117.15 404.11 9.5583 398.55 9.5583" stroke="currentColor" />
          <path d="m398.19 122.71v-25.864" stroke="currentColor" />
          <path d="m422.21 166v-19.398" stroke="currentColor" />
          <path d="m454.82 166v-10.058" stroke="currentColor" />
          <path d="m128.49 166v-4.8495" stroke="currentColor" />
          <polyline points="398.55 9.5583 398.55 1.1165 359.13 1.1165 359.13 9.5583" stroke="currentColor" />
          <polyline points="448.37 96.85 448.37 39.913 410.74 39.913" stroke="currentColor" />
          <polyline points="454.82 108.34 454.82 96.85 448.37 96.85" stroke="currentColor" />
          <polyline points="461.45 166 461.45 108.34 454.82 108.34" stroke="currentColor" />
          <polyline points="271.14 6.1456 253.93 6.1456 253.93 14.049" stroke="currentColor" />
          <polyline points="303.57 14.049 303.57 6.1456 297.3 6.1456" stroke="currentColor" />
          <polyline points="253.93 14.049 246.05 14.049 246.05 32.908" stroke="currentColor" />
          <polyline points="303.57 14.049 316.47 14.049 316.47 76.553" stroke="currentColor" />
          <polyline points="297.3 6.1456 297.3 0.21845 260.38 0.21845 260.38 6.1456" stroke="currentColor" />
          <polyline points="145.33 108.88 145.33 115.89 234.04 115.89 234.04 108.88" stroke="currentColor" />
          <polyline points="234.04 108.88 226.33 102.06 153.22 102.06 145.33 108.88 145.33 108.88" stroke="currentColor" />
          <polygon points="151.78 128.82 113.97 128.82 98.381 141.03 167.2 141.03" stroke="currentColor" />
          <path d="m394.79 128.46v37.539" stroke="currentColor" />
          <path d="m445.5 166v-37.539" stroke="currentColor" />
          <polyline points="422.21 128.46 448.01 128.46 435.11 116.25 405 116.25 392.1 128.46 404.11 128.46" stroke="currentColor" />
          <polyline points="366.12 102.96 340.31 90.743 316.47 90.743 290.67 102.96" stroke="currentColor" />
          <path d="m296.76 133.49c0-7.0049-5.5554-12.573-12.544-12.573-6.989 0-12.544 5.568-12.544 12.573" stroke="currentColor" />
          <path d="m271.67 140.67c0 7.0049 5.5554 12.573 12.544 12.573 6.989 0 12.544-5.568 12.544-12.573" stroke="currentColor" />
          <path d="m271.67 140.67v-7.1845" stroke="currentColor" />
          <path d="m284.22 166v-24.966" stroke="currentColor" />
          <path d="m296.76 133.49v7.1845" stroke="currentColor" />
          <path d="m498.91 121.46c0-7.0049-5.5554-12.573-12.544-12.573-6.989 0-12.544 5.568-12.544 12.573" stroke="currentColor" />
          <path d="m473.82 134.39c0 7.0049 5.5554 12.573 12.544 12.573 6.989 0 12.544-5.568 12.544-12.573" stroke="currentColor" />
          <path d="m473.82 134.39v-12.932" stroke="currentColor" />
          <path d="m486.36 166v-37.718" stroke="currentColor" />
          <path d="m498.91 121.46v12.932" stroke="currentColor" />
          <path d="m160.92 108.88l-7.7059-6.8252" stroke="currentColor" />
          <path d="m120.42 134.03l-6.6306-5.2087" stroke="currentColor" />
          <path d="m401.96 133.85h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m401.96 143.19h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m401.96 152.71h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m272.93 94.155h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m272.93 103.67h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m272.93 113.01h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m260.92 94.155h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m260.92 103.67h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m260.92 113.01h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m412.35 133.85h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m412.35 143.19h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m179.2 122.71h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m179.2 132.05h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m189.42 122.71h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m189.42 132.05h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m199.45 122.71h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m107.34 146.6h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m107.34 155.94h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m117.74 146.6h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m412.35 152.71h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71683 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m361.63 15.845h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m361.63 25.184h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m361.63 34.524h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m361.63 44.044h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m372.39 15.845h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m372.39 25.184h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m372.39 34.524h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m372.39 44.044h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m383.14 15.845h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m383.14 25.184h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m383.14 34.524h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m383.14 44.044h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m361.63 53.563h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m361.63 62.903h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m361.63 72.243h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m361.63 81.762h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71683-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m372.39 53.563h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m372.39 62.903h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m372.39 72.243h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m372.39 81.762h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.71682-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m383.14 53.563h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m383.14 62.903h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m383.14 72.243h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-0.89806 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m383.14 81.762h2.5089c1.0752 0 1.7921 0.71845 1.7921 1.7961v2.5146c0 1.0777-0.71682 1.7961-1.7921 1.7961h-2.5089c-1.0752 0-1.7921-0.71845-1.7921-1.7961v-2.5146c0-1.0777 0.89603-1.7961 1.7921-1.7961z" fill="currentColor" opacity=".3" />
          <path d="m91.75 161.51c-2.5089 0-4.4802 1.9757-4.4802 4.4903" stroke="currentColor" />
          <path d="m103.4 166c0-2.5146-1.9713-4.4903-4.4802-4.4903" stroke="currentColor" />
          <path d="m98.918 161.51c0-1.9757-1.6129-3.5922-3.5841-3.5922-1.9713 0-3.5841 1.6165-3.5841 3.5922" stroke="currentColor" />
          <path d="m69.349 160.61c-2.6881 0-5.0178 2.335-5.0178 5.0291" stroke="currentColor" />
          <path d="m75.801 153.79c-3.4049 0-6.2722 2.8738-6.2722 6.2864" stroke="currentColor" />
          <path d="m92.109 160.07c0-3.4126-2.8673-6.2864-6.2722-6.2864" stroke="currentColor" />
          <path d="m85.657 153.79c0-2.6942-2.3297-5.0291-5.0178-5.0291-2.6881 0-5.0178 2.335-5.0178 5.0291" stroke="currentColor" />
          <path d="m375.08 161.51c-2.5089 0-4.4802 1.9757-4.4802 4.4903" stroke="currentColor" />
          <path d="m386.72 166c0-2.5146-1.9713-4.4903-4.4802-4.4903" stroke="currentColor" />
          <path d="m382.24 161.51c0-1.9757-1.6129-3.5922-3.5841-3.5922s-3.5841 1.6165-3.5841 3.5922" stroke="currentColor" />
          <path d="m352.85 160.61c-2.6881 0-5.0178 2.335-5.0178 5.0291" stroke="currentColor" />
          <path d="m359.13 153.79c-3.4049 0-6.2722 2.8738-6.2722 6.2864" stroke="currentColor" />
          <path d="m375.43 160.07c0-3.4126-2.8673-6.2864-6.2722-6.2864" stroke="currentColor" />
          <path d="m369.16 153.79c0-2.6942-2.3297-5.0291-5.0178-5.0291-2.6881 0-5.0178 2.335-5.0178 5.0291" stroke="currentColor" />
        </g>
      </g>
    </g>
  </svg>
);

export default CityRight;

import React from 'react';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Clouds from '../components/Clouds';
import EolMessage from '../components/EolMessage';
import CityIllustrations from '../components/CityIllustrations';
import BallonIllustration from '../components/BallonIllustration';

const FrontPage = () => (
  <>
    <SEO title="Livvin er lagt ned" />

    <Clouds />

    <Layout>
      <Header />

      <main>
        <EolMessage />
        <CityIllustrations />
        <BallonIllustration />
      </main>
    </Layout>
  </>
);

export default FrontPage;
